import { en, ko, ja, zh } from "../../translation/randomNicknames"
import { getLanguage } from "../../translation/i18n"

function random(seed: number) {
  var x = Math.sin(seed) * 10000

  return x - Math.floor(x)
}

const generateRandomNickname = (userNickname: string, feedId: string) => {
  if (userNickname === null) {
    return ""
  }

  if (userNickname.length === 1) {
    userNickname = userNickname.concat(userNickname[0]).concat(userNickname[0])
  } else if (userNickname.length === 2) {
    userNickname = userNickname.concat(userNickname[0])
  }

  const nicknamesByLanguage: { [key: string]: Array<Array<string>> } = {
    ko: ko,
    ja: ja,
    en: en,
    ms: en,
    zh: zh,
  }

  const nicknameList = nicknamesByLanguage[getLanguage()]

  const firstNicknames = nicknameList[0]
  const secondNicknames = nicknameList[1]
  const thirdNicknames = nicknameList[2].slice(nicknameList[2].length / 2)

  const feedIdOnlyDigits = Number(feedId.replace(/[^0-9]/g, ""))

  const nicknameOnlyDigits = Number(userNickname.replace(/[^0-9]/g, ""))
    ? Number(userNickname.replace(/[^0-9]/g, ""))
    : userNickname.length *
      feedIdOnlyDigits *
      userNickname.charCodeAt(0) *
      userNickname.charCodeAt(1) *
      userNickname.charCodeAt(2)

  const firstNicknameList = firstNicknames.length
  const firstSeed = random(
    userNickname.charCodeAt(0) + feedIdOnlyDigits + nicknameOnlyDigits
  )

  const index = parseInt((firstSeed * firstNicknameList).toString())

  const secondNicknameList = secondNicknames.length
  const secondSeed = random(
    userNickname.charCodeAt(1) + feedIdOnlyDigits + nicknameOnlyDigits
  )

  const index2 = parseInt((secondSeed * secondNicknameList).toString())

  const thirdNicknameList = thirdNicknames.length
  const thirdSeed = random(
    userNickname.charCodeAt(2) + feedIdOnlyDigits + nicknameOnlyDigits
  )

  const index3 = parseInt((thirdSeed * thirdNicknameList).toString())

  // NOTE - 일부 국가의 닉네임 띄어쓰기 제거
  if (getLanguage() === "zh" || getLanguage() === "ja") {
    return `${firstNicknames[index]}${secondNicknames[index2]}${thirdNicknames[index3]}`
  }

  return `${firstNicknames[index]} ${secondNicknames[index2]} ${thirdNicknames[index3]}`
}

export default generateRandomNickname
