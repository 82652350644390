const mainBase = {
  DEV: process.env.REACT_APP_MAIN_DEV_SERVER_URL,
  QA: process.env.REACT_APP_MAIN_QA_SERVER_URL,
  PROD: process.env.REACT_APP_MAIN_PROD_SERVER_URL,
}

const snsBase = {
  DEV: process.env.REACT_APP_SNS_DEV_SERVER_URL,
  QA: process.env.REACT_APP_SNS_QA_SERVER_URL,
  PROD: process.env.REACT_APP_SNS_PROD_SERVER_URL,
}

export let snsServerBaseURL = snsBase.DEV
export let mainServerBaseURL = mainBase.DEV
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // development code
  snsServerBaseURL = snsBase.DEV
  mainServerBaseURL = mainBase.DEV
}

const apis = {
  account: {
    userData: `${mainServerBaseURL}/account/v4/users/`,
    emailLogin: `${mainServerBaseURL}/account/login/email/`,
    smsLogin: `${mainServerBaseURL}/account/v2/login/sms/`,
    getSMSAuthCode: `${mainServerBaseURL}/smsAuth/v3/signup/send/`,
    verifySMSAuthCode: `${mainServerBaseURL}/smsAuth/v3/signup/check/`,
  },

  report: {
    userControl: `${mainServerBaseURL}/report/v4/control`,
    reportDefinitions: `${mainServerBaseURL}/report/v4/reason_keys`,
  },

  feed: {
    feedLists: `${snsServerBaseURL}/feeds`,
    feedPost: `${snsServerBaseURL}/feeds/feedposts`,
    feedNotice: `${snsServerBaseURL}/feeds/feedposts/notice`,
    targetUserFeed: `${snsServerBaseURL}/feeds/others`,
    toggleLikeFeed: `${snsServerBaseURL}/reactions/likes/feedpost`,
    toggleLikeComment: `${snsServerBaseURL}/reactions/likes/comment`,
    receivedReports: `${mainServerBaseURL}/report/v4/simple-received-reports/`,
    feedComment: `${snsServerBaseURL}/reactions/comments`,
    myFeedStatus: `${snsServerBaseURL}/users`,
  },

  management: {
    feedPost: `${snsServerBaseURL}/management/feedposts`,
  },

  callRoom: {
    create: `${mainServerBaseURL}/voice/v2/room-call/waiting-rooms/`,
  },

  bookmark: {
    bookmark: `${snsServerBaseURL}/bookmarks`,
  },

  serverMaintenance:
    "https://08402bazq3.execute-api.ap-northeast-2.amazonaws.com/default/feed-service-maintenance-messages",
}

export default apis
